<template>
  <div class="container">
    <div class="list-header">
      <van-icon @click="$router.back(-1)" class="back-top-icon" name="arrow-left" />
      <div class="header-title">设备列表</div>
      <div @click="$router.push({path: '/deviceBind'})" class="header-left"><img src="@/accesst/index/jia.png" alt="" srcset="">&nbsp;绑定设备</div>
    </div>
    <div class="h85"></div>
    <div class="device-list">
      <div v-for="(item, index) in list" :key="index" class="device-item">
        <div class="item-head">
          <div class="item-head-t">{{item.deviceName || '眼保仪'}} SN：{{ item.deviceSn }}</div>
          <img v-if="!item.deviceStatus" src="@/accesst/device/off.png" class="item-head-status" alt="" srcset=""/>
          <img @click="onInput(item)" v-else src="@/accesst/device/on.png" class="item-head-status" alt="" srcset=""/>
        </div>
        <div class="item-info">
          <img @click="hrefDetail(item)" v-if="item.devicePic" :src="item.devicePic" class="item-img" alt="" srcset="" />
          <img @click="hrefDetail(item)" v-else src="@/accesst/index/device-default.png" class="item-img" alt="" srcset="" />
          <div class="item-cells">
            <div>
              <span class="item-cell-t">设备状态：</span>
              <span class="item-cell-status" :class="{'color-off': item.deviceStatus === false, 'color-on': item.deviceStatus === true}">
                {{ item.deviceStatus === true ? '在线' : '关机' }}
              </span>
            </div>
            <div>
              <!-- <div>&nbsp;</div> -->
              <span class="item-cell-t">正在使用：</span>
              <!-- <span class="item-cell-status">{{ item.nowBrowse ? ('浏览' + item.nowBrowse + ' ' + item.totalUseTime +' 分钟') : '-' }}</span> -->
              <span class="item-cell-status">{{ item.nowBrowse || '-' }}</span>
            </div>
            <div class="item-end">
              <div>
                <!-- <span class="item-cell-t">正在使用：</span> -->
                <!-- <span class="item-cell-status">{{ item.nowBrowse ? ('浏览' + item.nowBrowse + ' ' + item.totalUseTime +' 分钟') : '-' }}</span> -->
                <!-- <span class="item-cell-status">{{ item.nowBrowse || '-' }}</span> -->
              </div>
              <!-- <div class="item-end-line"> -->
                <!-- <span class="item-cell-t">家长控制&nbsp;&nbsp;&nbsp;</span>
                <van-switch v-model="item.deviceStatus" active-color="#00AD81" inactive-color="#CCCCCC" />
                <div v-if="item.deviceStatus" @click="onInput(item)" class="item-end-line-mask"></div>
                <div v-else class="item-end-line-mask"></div> -->
              <!-- </div> -->
              <div @click="hrefDetail(item)" class="item-end-btn">
                设备信息&nbsp;<van-icon class="item-end-btn-arrow" name="arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>      
    </div>
  </div>
</template>

<script>
import { Icon, RadioGroup, DatetimePicker, Popup, Picker, Switch, Dialog } from 'vant';
import { range, forEach } from 'lodash';

import { deviceAllList, deviceOff } from '@/api/h5';

export default {
  components: {[Icon.name]: Icon, [RadioGroup.name]: RadioGroup, [Switch.name]: Switch, [DatetimePicker.name]: DatetimePicker, [Popup.name]: Popup, [Picker.name]: Picker},
  data() {
    return {
      list: [],
      checked: true
    }
  },
  created() {
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,   // 禁止背景点击
      duration: 300,  //展示时间，0为持续展示
      overlay: true,  //是否显示背景遮罩层
    })
    this.getList();
  },
  methods: {
    getList() {
      deviceAllList({}).then(res => {
        console.log(res, 'deviceAllList');
        this.list = res.map(v => {
          v.deviceStatus === 0 ? v.deviceStatus = true : v.deviceStatus = false;
          return v;
        });
      })
    },    
    hrefDetail(item) {
      this.$router.push({path: '/deviceDetail', query: {item: JSON.stringify(item)}});
    },
    onInput(item) {
      console.log(item)      
      Dialog.confirm({
        title: '提示',
        message: '您确认要关闭此设备码？',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            setTimeout(done, 1000);
            deviceOff({deviceSn: item.deviceSn}).then(r => {
              this.$toast('关机成功');
              item.deviceStatus = false;
              this.getList();
            });
          } else {
            done();
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  .item-end-line {
    position: relative;
    .item-end-line-mask {
      position: absolute;
      width: 1rem;
      height: .4rem;
      top: 0;
      right: 0;
      z-index: 2;
      // background: red; 
    }
  }
  .header-left {
    display: flex;
    align-items: center;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 400;
    font-size: .27rem;
    color: #2B67D9;
    line-height: .4rem;
    text-align: left;
    font-style: normal;
    img {
      width: .3rem;
    }
  }

  /deep/ .van-switch{
    height: .3rem;
    font-size: .3rem;
    vertical-align: bottom;
  }
  .device-list {
    padding: 0 .29rem .29rem;
    .device-item {
      margin: .23rem auto 0;
      width: 6.92rem;
      height: 2.67rem;
      background: #F6F8F9;
      border-radius: .08rem;
      .item-end {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-end-btn {
          padding-top: .1rem;
          font-size: .23rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666;
          .item-end-btn-arrow {
            width: .23rem;
            height: .23rem;
            color: #666;
          }
        }
      }
      .item-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 .2rem;
        .item-img {
          width: 2.12rem;
          height: 1.60rem;
          margin-right: .29rem  ;
        }
        .item-cells {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 4.42rem;
          height: 1.60rem;
          .item-cell-t {
            font-size: .27rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #000000;
          }
          .item-cell-status {
            font-size: .27rem;
            font-family: PingFangSC, PingFang SC;
            color: #000;
            font-weight: 600;            
          }
          .color-on {
            color: #00AD81
          }
          .color-off {
            color: #AD0000
          }
          .color-leave {
            color: #666666;
          }
        }
      }
      .item-head {
        padding: .25rem .2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-head-t {
          font-size: .23rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .item-head-status {
          width: .31rem;
          height: .31rem;
        }
      }
    }
  }
  .h85 {
    height: .85rem;
  }
  .list-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    height: .85rem;
    padding: 0 .46rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
    .back-top-icon {
      width: .3rem;
      font-size: .32rem;
      color: #000;
      font-weight: bolder;
    }
    .header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
    }
    .header-left {
      // width: .3rem;
    }
  }
}
</style>